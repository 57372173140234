import { scrollFromTop } from '../../functions/scroll-from-top';

export const progressBar = {
    // TODO Set list of reachable points to toggle for color
    //    - titles
    //    - sub-titles
    //    - dots
    // TODO refaire les calculs de hauteur de reach
    // TODO Mettre les chiffres en sticky (ca section longue pour chiffre visible)

    //////////////////////////////////////
    // CORE
    //////////////////////////////////////
    init: ((elem:HTMLElement):Boolean => {
        progressBar.setParent(elem);
        progressBar.setSteps(elem);
        progressBar.setProgressBar(elem);
        if(progressBar.lastElem == undefined) {
            progressBar.lastElem = progressBar.getElemsToReach()[progressBar.getElemsToReach().length - 1];
        }
        progressBar.getProgressBar().style.maxHeight= `${progressBar.getMaxHeight()}px`;
        window.addEventListener('resize', progressBar.onResize);
        window.addEventListener('orientationchange', progressBar.onResize);
        progressBar.launchScroll();
        return true;
    }),
    launchScroll: (():Boolean => {
        document.addEventListener('scroll', progressBar.manageScroll);
        return true;
    }),
    restart: (():void => {
        
    }),
    destroyObject: (():void => {
        progressBar.resetNonPermanent();
        progressBar.lastElem = undefined;
        progressBar.progressBar = undefined;
        progressBar.parent = undefined;
        progressBar.resetListeners();
    }),
    resetNonPermanent: (():void => {
        progressBar.steps = undefined;
        progressBar.nbSteps = undefined;
    }),
    resetListeners: (():void => {
        document.removeEventListener('scroll', progressBar.manageScroll);
        window.removeEventListener('resize', progressBar.onResize);
    }),

    //////////////////////////////////////
    // MANAGER
    //////////////////////////////////////
    onResize: (():Boolean => {
        progressBar.resetListeners();
        progressBar.resetNonPermanent()
        progressBar.init(progressBar.getParent());
        progressBar.getProgressBar().style.maxHeight= `${progressBar.getMaxHeight()}px`;
        return true;
    }),
    manageScroll: (function():Boolean {
        progressBar.manageProgressBar();
        progressBar.manageSteps();
        return true;
    }),
    manageProgressBar: (():Boolean => {
        if(progressBar.isInsideParent()) {
            progressBar.getProgressBar().style.height = `${((progressBar.getParentLimitTop() - progressBar.getParentTop()) * 100) / progressBar.getParentHeight()}%`;
        }
        if(progressBar.isBeforeParent()) {
            progressBar.getProgressBar().style.height = '0%';
        }
        return true;
    }),
    manageSteps: (():Boolean => {
        // progressBar.setTestBars(progressBar.getElemsToReach());
        progressBar.getElemsToReach().forEach((elem:any, index:number) => {
            if(elem.classList.contains('reached')) {
                return;
            }
            if(window.scrollY + window.innerHeight / 2 + progressBar.getVariableDotSemiSize(elem) >= progressBar.getFromTop(elem)) {
                elem.classList.replace('to-reach', 'reached');
            }
        });
        return true;
    }),

    //////////////////////////////////////
    // CONST
    //////////////////////////////////////
    dotsSizes: {
        'title': 64,
        'before': 38,
        'arrow-steps': 38,
        'after': 38,
    },

    //////////////////////////////////////
    // VARS
    //////////////////////////////////////
    progressBar: undefined,
    parent: undefined,
    steps: undefined,
    nbSteps: undefined,
    lastElem: undefined,

    //////////////////////////////////////
    // GETTERS
    //////////////////////////////////////
    getProgressBar: (():HTMLElement => {
        return progressBar.progressBar;
    }),
    getParent: (():HTMLElement => {
        return progressBar.parent;
    }),
    getParentLimitTop: (():number => {
        return window.scrollY + window.innerHeight / 2;
    }),
    getParentLimitBottom: (():number => {
        return progressBar.getParentTop() + progressBar.getParentHeight();
    }),
    getParentTop: (():number => {
        return progressBar.getParent().offsetTop;
    }),
    getParentHeight: (():number => {
        return progressBar.getParent().offsetHeight;
    }),
    getSteps: (():HTMLCollection => {
        return progressBar.steps;
    }),
    getStepTop: ((elem:HTMLElement):number => {
        return progressBar.getParentTop() + elem.offsetTop;
    }),
    getElemsToReach: (():any => {
        return document.querySelectorAll('.to-reach');
    }),
    getVariableDotSemiSize: ((elem:Element):number => {
        try {
            return Object.keys(progressBar.dotsSizes).map((key:string):number => {
                if(elem.classList.contains(key)) {
                    return progressBar.dotsSizes[key];
                }
                return -1;
            }).filter(value => value >= 0)[0] / 2;
        }
        catch(e:any) {
            return 0;
        }
    }),
    getMaxHeight: (():number => {
        return (progressBar.getFromTop(progressBar.lastElem) - progressBar.getFromTop(progressBar.getParent())) + progressBar.lastElem.offsetHeight;
    }),
    getFromTop: ((element:HTMLElement | Element):number => {
        if(!element) {
            return 0;
        }
        var top = 0;
        
        top = scrollFromTop(element);
    
        if(element && element.classList.contains("title")) {
            top -= 13;
        }

        return top;
    }),

    //////////////////////////////////////
    // SETTERS
    //////////////////////////////////////
    setParent: ((elem:HTMLElement):void => {
        progressBar.parent = elem;
    }),
    setSteps: ((parent:HTMLElement):number => {
        return progressBar.getParentTop();
    }),
    setProgressBar: ((elem:HTMLElement):void => {
        progressBar.progressBar = elem.querySelector('.progress-bar');
    }),
    setTestBars: ((elems:Array<any>):void => {
        Array.from(document.querySelectorAll('.test-bar')).forEach((elem) => {
            elem.remove();
        });
        Array.from(elems).map((elem, key) => {
            let _elem = document.createElement('div');
            _elem.classList.add('test-bar');
            _elem.id = `test-bar-${key}`;
            _elem.style.position = 'absolute';
            _elem.style.left = '0px';
            _elem.style.top = `${progressBar.getFromTop(elem)}px`;
            _elem.style.width = '100%';
            _elem.style.height = '1px';
            _elem.style.background = 'red';
            _elem.style.zIndex = '2';
            document.querySelector('main').append(_elem);
        })
    }),

    //////////////////////////////////////
    // BOOLEANS
    //////////////////////////////////////
    isBeforeParent: (():Boolean => {
        return progressBar.getParentLimitTop() <= progressBar.getParentTop();
    }),
    isInsideParent: (():Boolean => {
        return progressBar.getParentLimitTop() > progressBar.getParentTop() && progressBar.getParentTop() < progressBar.getParentLimitBottom()
    }),
    isAfterParent: (():Boolean => {
        return progressBar.getParentTop() >= progressBar.getParentLimitBottom();
    }),

    isInsideStep: ((elem:HTMLElement):Boolean => {
        return progressBar.getParentLimitTop() > progressBar.getStepTop(elem) && progressBar.getStepTop(elem) < (progressBar.getStepTop(elem) + elem.offsetHeight);
    }),
    reachedStep: ((elem:HTMLElement):Boolean => {
        return progressBar.getParentLimitTop() > progressBar.getStepTop(elem) + elem.offsetHeight / 3;
    }),
}
