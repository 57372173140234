export const getFormDatas = function (id = null) {
    if(id == null || id == "" || typeof id != "string") {
        return null;
    }
    if(!document.forms[id]) {
        return null;
    }
    let retour = new Object();
    Array.from(document.forms[id].elements).forEach((elem:any):void => {
        if(elem.name != "" && elem.value != "") {
            if(elem.type == "checkbox") {
                if(!retour[elem.dataset.group]) {
                    retour[elem.dataset.group] = new Array();
                }
                if(elem.checked) {
                    retour[elem.dataset.group].push(elem.value);
                }
            }
            else if(elem.type == "radio") {
                if(elem.checked) {
                    retour[elem.name] = elem.value
                }
            }
            else {
                retour[elem.name] = elem.value;
            }
        }
    });
    return retour;
}