/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { calculator } = require("./src/components/animation/calculator");
const { progressBar } = require("./src/components/LoaderStepper/progressBar");
const { appMaker } = require("./src/components/ProjectMaker/makersFunc");
const { slider } = require("./src/components/Slider/slide");
const { popinManager } = require("./src/functions/caseManagers");

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location }) => {
    // Mettre switch futur si besoin plus de cas
    if(location.pathname !== "/") {
        calculator.close();
        progressBar.destroyObject();
        document.removeEventListener('scroll', popinManager);
    }
    else if(location.pathname !== "/project") {
        appMaker.close();
        document.querySelector('footer').style.removeProperty('display');
    }
};

exports.onPreRouteUpdate = ({ prevLocation }) => {
    if(prevLocation && prevLocation.pathname !== "/") {
        slider.close();
    }
};