import { Key, SyntheticEvent } from "react";
import { sendRequest } from "../../functions/ajax-manage";
import { getFormDatas } from "../../functions/form-manage";
import { scrollFromTop } from "../../functions/scroll-from-top";

import { closeModale, openModale, projectMakerModal } from '../Modale/appModale';

export const appMaker = {

    // Relier les boutons et le scroll à la progressBar et au step counter
    // Changer les visuels du Strapi
    // Mettre une page de connexion sur strapi.emeka.fr qui redirige vers le
    // bon url avec les bons credentials

    ///////////////////////////////
    //////////// CORE /////////////
    ///////////////////////////////

    // VARS
    parent: undefined,
    step: 1,
    totalStep: 0,
    scrollBuffer: 0,
    initialized: false,
    logMode: false,

    // INITS
    init: (value:number, parent_id:string, logMode:boolean = false) => {
        appMaker.parent = document.querySelector(`#${parent_id}`);
        appMaker.logMode == true && console.log('init');
        appMaker.step = 1;
        appMaker.totalStep = value;
        appMaker.startEventListeners();
        appMaker.updateProgressBar();
        appMaker.initialized = true;
        appMaker.logMode = logMode;
    },
    close: () => {
        appMaker.logMode == true && console.log('close');
        appMaker.initialized && appMaker.stopEventListeners();
        appMaker.initialized = false;
        appMaker.parent = undefined;
        appMaker.scrollBuffer= 0;
        appMaker.initialized= false;
    },
    startEventListeners: (() => {
        appMaker.logMode == true && console.log('startEventListeners');
        appMaker.startListenEnter();
        appMaker.startListenBackspace();
        appMaker.startListenMouseWheel();
        appMaker.startListenArrowDown();
        appMaker.startListenArrowUp();
        appMaker.initialized = true;
        window.addEventListener('resize', appMaker.onResize);
        window.addEventListener('orientationchange', appMaker.onResize);
    }),
    stopEventListeners: (() => {
        window.removeEventListener('orientationchange', appMaker.onResize);
        window.removeEventListener('resize', appMaker.onResize);
        appMaker.logMode == true && console.log('stopEventListeners');
        appMaker.stopListenArrowUp();
        appMaker.stopListenArrowDown();
        appMaker.stopListenMouseWheel();
        appMaker.stopListenBackspace();
        appMaker.stopListenEnter();
    }),
    onResize: (() => {
        let _parent_id = appMaker.parent.id;
        let _totalStep = appMaker.totalStep;
        let _logMode = appMaker.logMode;
        appMaker.close();
        appMaker.init(_totalStep, _parent_id, _logMode);
    }),
    // ENTER
    startListenEnter: (() => {
        appMaker.logMode == true && console.log('startListenEnter');
        document.addEventListener("keyup", appMaker.handleKeyEnterEvent);
    }),
    stopListenEnter: (() => {
        appMaker.logMode == true && console.log('stopListenEnter');
        document.removeEventListener("keyup", appMaker.handleKeyEnterEvent);
    }),
    // BACKSPACE
    startListenBackspace: (() => {
        appMaker.logMode == true && console.log('startListenBackspace');
        document.addEventListener("keyup", appMaker.handleKeyBackspaceEvent);
    }),
    stopListenBackspace: (() => {
        appMaker.logMode == true && console.log('stopListenBackspace');
        document.removeEventListener("keyup", appMaker.handleKeyBackspaceEvent);
    }),
    // MOUSE WHEEL
    startListenMouseWheel: (() => {
        appMaker.logMode == true && console.log('startListenMouseWheel');
        document.addEventListener('wheel', appMaker.handleMouseWheelEvent, {passive: false});
    }),
    stopListenMouseWheel: (() => {
        appMaker.logMode == true && console.log('stopListenMouseWheel');
        document.removeEventListener('wheel', appMaker.handleMouseWheelEvent);
    }),
    // ARROW DOWN
    startListenArrowDown: (() => {
        appMaker.logMode == true && console.log('startListenArrowDown');
        document.addEventListener('keyup', appMaker.handleListenArrowDownEvent);
    }),
    stopListenArrowDown: (() => {
        appMaker.logMode == true && console.log('stopListenArrowDown');
        document.removeEventListener('keyup', appMaker.handleListenArrowDownEvent);
    }),
    // ARROW UP
    startListenArrowUp: (() => {
        appMaker.logMode == true && console.log('startListenArrowUp');
        document.addEventListener('keyup', appMaker.handleListenArrowUpEvent);
    }),
    stopListenArrowUp: (() => {
        appMaker.logMode == true && console.log('stopListenArrowUp');
        document.removeEventListener('keyup', appMaker.handleListenArrowUpEvent);
    }),

    ///////////////////////////////
    ////////// BOOLEAN ////////////
    ///////////////////////////////
    isLastStep: ():boolean => {
        appMaker.logMode == true && console.log('isLastStep');
        appMaker.logMode == true && console.log(appMaker.step);
        appMaker.logMode == true && console.log(appMaker.totalStep);
        if (appMaker.step >= appMaker.totalStep) {
            return true;
        }
        return false;
    },

    ///////////////////////////////
    ////////// HANDLERS ///////////
    ///////////////////////////////
    handleQuestionNext: (e:SyntheticEvent | KeyboardEvent | any, elemntUp:Element = null, elemntDown:Element = null) => {
        appMaker.logMode == true && console.log('handleQuestionNext');
        if ( (appMaker.step) >= appMaker.totalStep && elemntUp === null) {
            appMaker.handleOpenCheckout();
        }
        else if (
            ((appMaker.step) < appMaker.totalStep && elemntUp === null && elemntDown === null) 
            || 
            ((appMaker.step) < appMaker.totalStep && elemntUp === null && elemntDown !== null)) 
        {
            appMaker.setStep(appMaker.step + 1);
            let currentE = elemntDown !== null ? elemntDown : e.currentTarget.parentElement.parentElement;
            currentE.classList.replace('current', 'completed');
            currentE.nextSibling.classList.add('current');
            appMaker.setButton(appMaker.step);
        }
        else if ( appMaker.step > 1 && elemntUp !== null && elemntDown === null ) {
            appMaker.setStep(appMaker.step - 1);
            elemntUp.classList.remove('current');
            elemntUp.previousSibling.classList.replace('completed','current');
            appMaker.setButton(appMaker.step);
        }
        else {
    
        }
        
    },

    handleClickUp:(e:SyntheticEvent | MouseWheelEvent | KeyboardEvent) => {
        if(window.outerHeight <= 650 || window.innerHeight <= 650) {
            if(appMaker.step > 1) {
                appMaker.setStep(appMaker.step - 1);
                window.scrollTo(0, scrollFromTop(appMaker.parent.querySelectorAll('.question')[appMaker.step - 1]) - 50);
                appMaker.setButton();
            }
            return false;
        }
        appMaker.logMode == true && console.log('handleClickUp');
        const elemntUp = document.querySelector('.question.current');
        appMaker.handleQuestionNext(e, elemntUp, null);
    },
    handleClickDown: (e:SyntheticEvent | KeyboardEvent | MouseWheelEvent) => {
        if(window.outerHeight <= 650 || window.innerHeight <= 650) {
            if(appMaker.step < appMaker.totalStep) {
                appMaker.setStep(appMaker.step + 1);
                window.scrollTo(0, scrollFromTop(appMaker.parent.querySelectorAll('.question')[appMaker.step - 1]) - 50);
                appMaker.setButton();
            }
            return false;
        }
        appMaker.logMode == true && console.log('handleClickDown');
        const elemntDown = document.querySelector('.question.current');
        appMaker.handleQuestionNext(e, null, elemntDown);
    },

    handleCheckLabel: (e:SyntheticEvent) => {
        appMaker.logMode == true && console.log('handleCheckLabel');
        const currentCase = e.currentTarget;
        currentCase.classList.toggle('option_checked');
    },

    handleCheckCase: (e:SyntheticEvent) => {
        appMaker.logMode == true && console.log('handleCheckCase');
        const currentFields = e.currentTarget.querySelector('.checkbox-active');
        currentFields.classList.toggle('checkbox-empty');
        currentFields.parentElement.classList.toggle('checked');
    },

    handleKeyEnterEvent: (e:KeyboardEvent) => {
        if(!appMaker.initialized) {
            return false;
        }
        appMaker.logMode == true && console.log('handleKeyEnterEvent');
        if (e.keyCode === 13) {
            event.preventDefault();
            appMaker.handleClickDown(e);
        }
    },

    handleKeyBackspaceEvent: (e:KeyboardEvent) => {
        if(!appMaker.initialized) {
            return false;
        }
        appMaker.logMode == true && console.log('handleKeyBackspaceEvent');
        if (e.keyCode === 8) {
            event.preventDefault();
            appMaker.handleClickUp(e);
        }
    },

    handleListenArrowDownEvent: ((e:KeyboardEvent) => {
        if(!appMaker.initialized) {
            return false;
        }
        appMaker.logMode == true && console.log('handleListenArrowDownEvent');
        if (e.keyCode === 40) {
            event.preventDefault();
            appMaker.handleClickDown(e);
        }
    }),
    
    handleListenArrowUpEvent: ((e:KeyboardEvent) => {
        if(!appMaker.initialized) {
            return false;
        }
        appMaker.logMode == true && console.log('handleListenArrowUpEvent');
        if (e.keyCode === 38) {
            event.preventDefault();
            appMaker.handleClickUp(e);
        }
    }),

    handleMouseWheelEvent: ((e:MouseWheelEvent) => {
        if(!appMaker.initialized) {
            return false;
        }
        if(window.innerHeight < 650  || window.outerHeight < 650) {
            let _step = 1;
            appMaker.parent.querySelectorAll('.question').forEach((elem:Element, i:number) => {
                // console.log(elem);
                if(window.scrollY + window.outerHeight - 150 >= scrollFromTop(elem)) {
                    elem.classList.add('reached');
                    _step = i + 1;
                }
                else {
                    elem.classList.remove('reached');
                }
            })
            appMaker.setStep(_step);
            appMaker.setButton();
            return false;
        }
        else {
            appMaker.logMode == true && console.log('handleMouseWheelEvent');
            e.preventDefault();
            if(Math.sign(appMaker.scrollBuffer) < 0 && Math.sign(e.deltaY) > 0) {
                appMaker.scrollBuffer = 0;
            }
            if(Math.sign(appMaker.scrollBuffer) > 0 && Math.sign(e.deltaY) < 0) {
                appMaker.scrollBuffer = 0;
            }
            appMaker.scrollBuffer += Math.sign(e.deltaY);
            if(appMaker.scrollBuffer > 5) {
                appMaker.handleClickDown(e);
                appMaker.scrollBuffer = 0;
            }
            if(appMaker.scrollBuffer < -5) {
                appMaker.handleClickUp(e);
                appMaker.scrollBuffer = 0;
            }
        }
    }),

    handleOpenCheckout: () => {
        appMaker.logMode == true && console.log('handleOpenCheckout');
        openModale(projectMakerModal(appMaker.initModale, appMaker.startEventListeners));
    },

    ///////////////////////////////
    /////////// OTHERS ////////////
    ///////////////////////////////
    updateProgressBar: () => {
        appMaker.logMode == true && console.log('updateProgressBar');
        document.getElementById('my-bar').style.width = `${(100 / (appMaker.totalStep) ) * (appMaker.step)}%`;        
        document.getElementById('tooltip-progress').style.left = `${(100 / (appMaker.totalStep) ) * (appMaker.step)}%`;        
    },

    setStep: (value:number) => {
        appMaker.logMode == true && console.log('setStep');
        if (value == appMaker.totalStep) {
            document.getElementById('tooltip-progress').classList.add('end-progress');
        }
        else {
            document.getElementById('tooltip-progress').classList.remove('end-progress');
        }
        appMaker.step = value;
        document.getElementById('step-up').innerHTML = appMaker.step.toString();
        document.getElementById('step-progress').innerHTML = appMaker.step.toString();
        appMaker.updateProgressBar();
    },

    setButton: (():void => {
        document.querySelector('.btn-next_mob').innerHTML = appMaker.step >= appMaker.totalStep ? 'Recevoir le devis' : 'Question suivante';
    }),

    initModale: () => {
        appMaker.logMode == true && console.log('initModale');
        appMaker.stopEventListeners();
        document.getElementById('project-form').addEventListener('submit', (e:Event) => {
            e.preventDefault();
            sendRequest(
                'https://emeka.fr/back/devis',
                'POST',
                {
                    action: 'devis',
                    datas: {
                        ...getFormDatas('project'),
                        ...getFormDatas('project-form')
                    },
                }
            )
            .then(res => res.json())
            .then(function(response) {
                (result) => {
                    appMaker.logMode == true && console.log(result);
                    document.forms['project'].reset();
                    document.forms[' project-form'].reset();
                    closeModale(appMaker.startEventListeners);
                },
                // Remarque : il faut gérer les erreurs ici plutôt que dans
                // un bloc catch() afin que nous n’avalions pas les exceptions
                // dues à de véritables bugs dans les composants.
                (error) => {
                    appMaker.logMode == true && console.log(error);
                    appMaker.logMode == true && console.log(error);
                    document.forms['project'].reset();
                    document.forms['project-form'].reset();
                    closeModale(appMaker.startEventListeners);

                }
            }).catch(function(error) {
                appMaker.logMode == true && console.log(error);
                document.forms['project'].reset();
                document.forms['project-form'].reset();
                closeModale(appMaker.startEventListeners);

                // TODO Add log error message on div#error
            });
        })
    }
}