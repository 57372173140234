export const slider = {

    parent: undefined,
    isDown: false,
    startX: undefined,
    scrollLeft: undefined,
    slider: (() => {
        return slider.parent;
    }),
    setSlider: ((value) => {
        slider.parent = value;
    }),
    ///////////////////////////////////////
    init: ((parent:HTMLElement) => {
        slider.setSlider(parent);
        slider.launchListeners();
    }),

    close: (() => {
        slider.setSlider(null);
        slider.removeListeners();
    }),
    ///////////////////////////////////////
    launchListeners: (() => {
        slider.slider().addEventListener('mousedown', slider.onMouseDown, true);
        slider.slider().addEventListener('mouseleave', slider.onMouseLeave, true);
        slider.slider().addEventListener('mouseup', slider.onMouseUp, true);
        slider.slider().addEventListener('mousemove', slider.onMouseMove, true);
    }),

    removeListeners: (() => {
        slider.slider() && slider.slider().removeEventListener('mousedown', slider.onMouseDown, true);
        slider.slider() && slider.slider().removeEventListener('mouseleave', slider.onMouseLeave, true);
        slider.slider() && slider.slider().removeEventListener('mouseup', slider.onMouseUp, true);
        slider.slider() && slider.slider().removeEventListener('mousemove', slider.onMouseMove, true);
    }),
    ///////////////////////////////////////
    onMouseDown: ((e:MouseEvent) => {
        slider.isDown = true;
        slider.slider().classList.add('active');
        slider.startX = e.pageX - slider.slider().offsetLeft;
        slider.scrollLeft = slider.slider().scrollLeft;
    }),
    onMouseLeave: ((e:MouseEvent | any) => {
        // if(e.target.id == slider.slider().id) {
        //     slider.isDown = false;
        //     slider.slider().classList.remove('active');
        // }
    }),
    onMouseUp: ((e:MouseEvent) => {
        slider.isDown = false;
        slider.slider().classList.remove('active');
    }),
    onMouseMove: ((e:MouseEvent) => {
        if(!slider.isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.slider().offsetLeft;
        const walk = (x - slider.startX) * 2;
        slider.slider().scrollLeft = slider.scrollLeft - walk;
    }),
};