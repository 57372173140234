var myHeaders = typeof Headers == 'undefined' ? {} : new Headers();

const fetch_post:RequestInit = {
    method: 'POST',
    headers: myHeaders,
    mode: 'no-cors',
    cache: 'default',
    referrerPolicy: "no-referrer" 
};

const fetch_get:RequestInit = {
    method: 'GET'
};

export const sendRequest = function(url:RequestInfo = null, method:string = "GET", datas:Object = {}):Promise<Response> {
    if(url == null || url == "" || typeof url != 'string') {
        console.error('Missing required parameters');
        return;
    }
    if(['GET', 'POST'].indexOf(method, 0) < 0) {
        console.error('Not allowed method');
        return;
    }
    if(method == 'GET') {
        console.info('GET');
        return fetch(url, fetch_get);
    }
    if(method == 'POST') {
        console.info('POST');
        return fetch(
            url,
            {...fetch_post, body: JSON.stringify(datas)}
        );
    }
    return;
}