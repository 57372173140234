// const step1 = [0, 1, 2];
// const step2 = [3, 4, 5];
// const step3 = [6, 7, 8];

import { scrollFromTop } from '../../functions/scroll-from-top';

function resetCheck() {
    document.querySelector('#calc-list').querySelectorAll('.calculator-item').forEach((elem) => {
        elem.classList.remove('checked');
    });
}

function reloadCycle() {
    document.querySelector('.modal-content').classList.remove('last-step');
}

function popin() {
    return document.getElementById('modal-devis');
}

export const toggle = function() {
    return document.querySelector('#popin-toggle');
}

function onScrollCanLaunchAnim() {
    const axe_y = 1335;
    if(window.scrollY >= axe_y && calculator.canPopinAppear() == true) {
        popin().classList.remove('popin-hidden');
        calculator.animed == false && calculator.animCycle();
    }
}

export const calculator = {

    canPopinAppear: (():boolean => {
        calculator.logMode && console.log('canPopinAppear');
        if(window.sessionStorage.canPopinAppear == "false") {
            return false;
        }
        else if(calculator.crossedTrigger() || !calculator.popin().classList.contains('popin-hidden')) {
            return true;
        }
    }),

    setcanPopinAppear: ((val: boolean):void => {
        calculator.logMode && console.log('setcanPopinAppear');
        if(typeof window == 'undefined') {
            return;
        }
        else {
            window.sessionStorage.canPopinAppear = val;
        }
    }),

    initialized: undefined,
    parentElem: undefined,
    childrenElem: undefined,
    trigger: undefined,
    triggerSelector: undefined,
    logMode: undefined,

    popin: (() => {
        calculator.logMode && console.log('popin');
        return popin();
    }),

    toggle: (() => {
        calculator.logMode && console.log('toggle');
        return toggle();
    }),

    restart : ((trigger:string = null):void => {
        calculator.logMode && console.log('restart');
        if(typeof trigger === 'string' && document.querySelector(trigger) != null) {
            calculator.trigger = document.querySelector(trigger);
            calculator.triggerSelector = trigger;
        }
        if(calculator.initialized || calculator.triggerSelector != undefined) {
            resetCheck();
            reloadCycle();
            calculator.init(calculator.triggerSelector, true);
        }
    }),
    
    init: ((trigger:string = null, restart:boolean = false, logMode:boolean = false):boolean => {
        calculator.logMode && console.log("init");
        if(typeof trigger === 'string' && document.querySelector(trigger) != null) {
            calculator.trigger = document.querySelector(trigger);
            calculator.triggerSelector = trigger;
        }
        else if(calculator.trigger != undefined && calculator.triggerSelector != undefined) {

        }
        else {
            return false;
        }
        if(calculator.canPopinAppear() == true || restart) {
            calculator.logMode && console.log("popin can appear");
            calculator.setcanPopinAppear(true);
            calculator.crossedTrigger() && popin().classList.remove('popin-hidden');
            toggle().classList.remove('shown');
        }
        else {
            calculator.logMode && console.log("popin cannot appear");
            popin().classList.add('popin-hidden');
            toggle().classList.add('shown');
        }
        calculator.logMode = logMode || false;
        document.querySelector('.modal-content').classList.remove('last-step');
        calculator.parentElem = document.querySelector('#calc-list');
        calculator.childrenElem = Array.from(document.querySelector('#calc-list').querySelectorAll('.calculator-item'));
        calculator.parentElem.style.removeProperty('top');
        calculator.startAnim(restart);
        return true;
    }),

    crossedTrigger: (():boolean => {
        return window.scrollY + window.outerHeight / 2 >= scrollFromTop(calculator.trigger) + (calculator.trigger.offsetHeight / 2);
    }),

    // TODO Set two array variables to push, easier to clear on destroy
    setTime0: undefined,
    setTime1: undefined,
    setTime2: undefined,
    setTime3: undefined,
    setTime4: undefined,
    setTime5: undefined,
    setTime6: undefined,
    setTime7: undefined,
    setTime8: undefined,
    setTime9: undefined,
    setInterval1: undefined,
    
    anim: (():void => {
        calculator.logMode && console.log('anim');
        calculator.setTime0 = setTimeout(() => {
            calculator.parentElem.style.top = '-266px';
            calculator.setTime1 = setTimeout(() => {
                calculator.childrenElem[7].classList.add('checked');
            },500);
            calculator.setTime2 = setTimeout(() => {
                calculator.parentElem.style.top = '-133px';
                calculator.setTime3 = setTimeout(() => {
                    calculator.childrenElem[5].classList.add('checked');
                    calculator.setTime4 = setTimeout(() => {
                        calculator.childrenElem[4].classList.add('checked');         
                    },450);
                },500);
                calculator.setTime5 = setTimeout(() => {
                    calculator.parentElem.style.top = '0px';
                    calculator.setTime6 = setTimeout(() => {
                        calculator.childrenElem[2].classList.add('checked');                        
                    },500);
                    calculator.setTime7 = setTimeout(() => {
                        calculator.parentElem.style.display = 'none';
                        calculator.parentElem.style.removeProperty('top');
                        resetCheck();
                        document.querySelector('.modal-content').classList.add('last-step');
                        calculator.setTime8 = setTimeout(() => {
                            reloadCycle();
                            calculator.parentElem.style.removeProperty('display');
                        },3000);
                    },1500);
                },1500);
            },1500);
        },1500);
    }),


    appeared: false,
    animed: false,
    isToggle: false,

    animCycle: (():void => {
        calculator.logMode && console.log('animCycle');
        calculator.animed = true;
        calculator.anim();
        calculator.setTime9 = setTimeout(() => {
            return;
        }, 500);
        calculator.setInterval1 = setInterval(() => {
            calculator.anim()
        },9000);
    }),

    close: ((all:boolean = false):void => {
        calculator.logMode && console.log('close');
        calculator.setcanPopinAppear(false);
        calculator.setTime0 != undefined && clearTimeout(calculator.setTime0)
        calculator.setTime1 != undefined && clearTimeout(calculator.setTime1)
        calculator.setTime2 != undefined && clearTimeout(calculator.setTime2)
        calculator.setTime3 != undefined && clearTimeout(calculator.setTime3)
        calculator.setTime4 != undefined && clearTimeout(calculator.setTime4)
        calculator.setTime5 != undefined && clearTimeout(calculator.setTime5)
        calculator.setTime6 != undefined && clearTimeout(calculator.setTime6)
        calculator.setTime7 != undefined && clearTimeout(calculator.setTime7)
        calculator.setTime8 != undefined && clearTimeout(calculator.setTime8)
        calculator.setTime9 != undefined && clearTimeout(calculator.setTime9)
        calculator.setInterval1 != undefined && clearInterval(calculator.setInterval1)
        calculator.animed = false;
        popin() && popin().classList.add('popin-hidden');
        toggle() && toggle().classList.add('shown');
        document.removeEventListener('scroll', onScrollCanLaunchAnim);
        if(all) {
            calculator.initialized = undefined;
            calculator.parentElem = undefined;
            calculator.childrenElem = undefined;
            calculator.trigger = undefined;
            calculator.triggerSelector = undefined;
        }
    }),

    startAnim: ((restart:boolean = false, logMode:boolean = false):void => {
        calculator.logMode && console.log('startAnim');
        if(restart) {
            calculator.logMode && console.log('restart');
            popin().classList.remove('popin-hidden');
            calculator.animed == false && calculator.animCycle();
            return;
        }
        if(typeof logMode == "boolean") {
            calculator.logMode = logMode;
        }
        
        document.addEventListener('scroll', onScrollCanLaunchAnim);

    }),
}