import { calculator as animation, calculator } from '../components/animation/calculator';
import { scrollFromTop } from './scroll-from-top';

// HOME

export const popinManager = function() {
    if(window.outerWidth > 768) {
        if(animation.canPopinAppear() == true) {
            calculator.popin().classList.remove('popin-hidden');
            calculator.toggle().classList.remove('shown');
        }
        else {
            calculator.popin().classList.add('popin-hidden');
            calculator.toggle().classList.add('shown');
        }
    }
}

