function modale():HTMLElement {
    return document.getElementById('modale');
}

function modaleContainer():HTMLElement {
    return document.getElementById('modale-container');
}

function modaleContent():HTMLElement {
    return document.getElementById('modale-content');
}

function modaleClose():HTMLElement {
    return document.getElementById('modale-close');
}

export function openModale(params:params) {
    modale().classList.add('opened');
    params.modaleClass != undefined && modale().classList.add(params.modaleClass);
    params.contentClass != undefined && modaleContainer().classList.add(params.contentClass);
    modaleContent().innerHTML = closePart() + params.content;
    params.onOpen && params.onOpen();
    modale().addEventListener('click', function(e:MouseEvent) {
        if(e.target.id == 'modale') {
            closeModale(params.onClose);
        }
    });
    modale().addEventListener('keyup', function(e:KeyboardEvent) {
        if(e.keyCode === 27) {
            closeModale(params.onClose);
        }
    });
        modaleClose().addEventListener('click', function(e:MouseEvent) {
        closeModale(params.onClose);
    });
}

export function closeModale(onClose:Function = null) {
    modale().classList.remove('opened');
    modaleContainer().classList.remove(...modaleContainer().classList);
    modaleContent().innerHTML = "";
    onClose != null && onClose();
}

function closePart() {
    return `<span id="modale-close">+</span>`;
}

// ProjectMaker Modale

export function projectMakerModal(onOpen:Function = null, onClose:Function = null) {
    return {
        onOpen: onOpen,
        onClose: onClose,
        contentClass: 'project-maker',
        content: `
        <h2>Vous y etes presque...</h2>
        <p>Veuillez remplir ce court formulaire et nous seront en mesure d'estimer votre projet et de vous recontacter</p>
        <form id="project-form" method="post">
            <div id="ctn-form-modale">
                <input type="text" name="firstName" placeholder="Votre prénom" required/>
                <input type="text" name="lastName" placeholder="Votre nom de famille" required/>
                <input type="mail" name="email" placeholder="Votre adresse e-mail" required/>
            </div>
            <div id="radios">
                <h3>Qui etes vous ?</h3>
                <ul>
                    <li class="radio-choice">
                        <input type="radio" name="who_you_are" id="who_you_are-decision-1" value="Je suis un décideur(BOSS)" required/>
                        <div class="radio_check"></div>
                        <label for="who_you_are-decision-1">
                            <span>Je suis un décideur(BOSS)</span>
                        </label>
                    </li>
                    <li class="radio-choice">
                        <input type="radio" name="who_you_are" id="who_you_are-decision-2" value="Je suis un créateur(DESIGNER)" required/>
                        <div class="radio_check"></div>
                        <label for="who_you_are-decision-2">
                            <span>Je suis un créateur(DESIGNER)</span>
                        </label>
                    </li>

                </ul>
            </div>
            <button type="submit" name="devis">Obtenir le devis</button>
        </form>`
    };
}

// <li class="radio-choice">
// <input type="radio" name="who_you_are" id="who_you_are-decision-3" value="Je suis un d'étudiant" required/>
// <div class="radio_check"></div>
// <label for="who_you_are-decision-3">
//     <span>Je suis un d'étudiant</span>
// </label>
// </li>

interface params {
    onOpen?: Function,
    onClose?: Function,
    contentClass?: string,
    modaleClass?: string,
    content: string,
}